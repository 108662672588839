import React, { useRef, useState } from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import SEO from "../../components/SEO";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { navigate } from "gatsby";

export default function Index() {
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comments: "",
    'bot-field': "",
  })

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!token) {
      console.log("No token");
      setToken(false)
    } else {
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...formData
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch((error) => alert(error));
    }
  };

  return (
    <Layout>
      <SEO title={"Contact Us"} />
      <div className="is-relative">
        <Navbar />
        <section className="section">
          <div className="p-5b p-4-mobile has-background-light">
            <div className="container ml-0 mr-auto pt-5b">
              <div className="content">
                <div className="columns">
                  <div className="column is-two-thirds-desktop">
                    <h1>Contact Us</h1>

                    <p>
                      This site is managed by the <a href="https://tmwa.com" rel="noreferrer" target="_blank">Truckee Meadows Water
                        Authority</a>.
                      {/* If you’d like to receive periodic newsletters
                        from TMWA, subscribe to our newsletter. */}
                      {' '}If you’d like to
                      learn more about any specific aspect of water resource
                      management in the region, please let us know in the
                      comment box.
                    </p>
                    <div className="columns">
                      <div className="column is-two-thirds-fullhd">
                        <form
                          name="contact"
                          method="POST"
                          action="/contact/thanks/"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          className=""
                          onSubmit={handleSubmit}
                        >
                          <input
                            type="hidden"
                            name="form-name"
                            value="contact"
                            aria-label="Hidden field to support form submissions without JavaScript"
                          />
                          <div hidden>
                            <label>
                              Don’t fill this out:{" "}
                              <input
                                name="bot-field"
                                onChange={handleChange}
                                aria-label="Bot field - don't fill this out"
                              />
                            </label>
                          </div>
                          <div className="field mb-4">
                            <label className="label" htmlFor={"name"}>
                              Your name
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={"text"}
                                name={"name"}
                                aria-label="Name"
                                id={"name"}
                                required={true}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="field mb-4">
                            <label className="label" htmlFor={"email"}>
                              Email
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={"email"}
                                name={"email"}
                                aria-label="Email"
                                id={"email"}
                                required={true}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="field mb-4">
                            <label className="label" htmlFor={"comments"}>
                              Comments
                            </label>
                            <div className="control">
                              <textarea
                                className="textarea"
                                name={"comments"}
                                id={"comments"}
                                aria-label="Comments (limited to 120 characters)"
                                maxlength="120"
                                onChange={handleChange}
                              />
                              <small>(Limited to 120 characters.)</small>
                            </div>
                          </div>
                          {/* <div className="field mb-4">
                          <input
                            type="checkbox"
                            id={"newsletter-subscribe"}
                            name={"newsletter-subscribe"}
                            aria-label="Subscribe to our newsletter"
                          ></input>
                          <label
                            className="ml-2"
                            htmlFor={"newsletter-subscribe"}
                          >
                            Subscribe to our newsletter
                          </label>
                        </div> */}
                          <div className={`${token === false ? "invalid" : ""}`}>
                            <HCaptcha
                              sitekey="88acee15-f985-472b-a22e-ea4f6f9cd25a"
                              onVerify={setToken}
                              ref={captchaRef} />
                          </div>
                          {token === false ?
                            <article class="message is-warning">
                              <div class="message-body">
                                Please verify that you are human
                              </div>
                            </article>
                            : null}
                          <div className="field my-5">
                            <button className="button is-primary" type="submit">
                              Submit
                            </button>
                          </div>
                        </form>
                        <small>
                          Any information provided will not be will not be sold or
                          exchanged.
                          {/* If you subscribe to our newsletter, you can
                        expect to receive TMWA newsletters 3-4 times per year. */}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
